import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StoreProvider } from "easy-peasy";
import MainStore from "./Store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StoreProvider store={MainStore}>
    <React.StrictMode>
      <App />
      <ToastContainer />
    </React.StrictMode>
  </StoreProvider>
);

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import home from "../Image/home.png";

import { useStoreState } from "easy-peasy";
import meta from "../Image/meta.png";
import walletconnect from "../Svg/walletconnect.svg";
import {
  Allow,
  Approve,
  Web3_provider,
  convertToLowercase,
  fromwie,
  get_ERC_20_Contract,
  get_Lottery_Contract,
  mainprovider,
  toByte32,
} from "../../Web3/web3";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";
import { LOTTERY_ADDRESS } from "../../Web3/Contract";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import pot from "../Image/pot.png";
import { Modal } from "react-bootstrap";
import { AiOutlineCopyrightCircle } from "react-icons/ai";

const success = (msg) => toast.success(msg);
const toasterror = (msg) => toast.error(msg);
function Home({ Dissconnect, Metamask, WalletC }) {
  const web3 = Web3_provider();
  const user = useStoreState((state) => state.user);
  const [contract, setContract] = useState();
  const [open, setOpen] = useState();
  const [ticket, setTickers] = useState(1);
  const [maxTicketPerWallet, setMaxTicketPerWallet] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [token, setToken] = useState("");
  const [ticketPrice, setTicketPrice] = useState(0);
  const [owenr, setOwner] = useState("");
  const [tokenPriceAPI, setTokenPriceAPI] = useState(0.13);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [ticketbought, setTicketBought] = useState(0);
  const [lotteryID, setLotteryID] = useState(0);
  const [lotteryBalance, setLotteryBalance] = useState(0);
  const [lotteryHistory, setLotteryHistory] = useState();
  const [allpoolvalue, setPoolvalue] = useState(0);
  const [totalParticipents, setTotalParticipemts] = useState();

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    const init = async () => {
      try {
        const contract = await get_Lottery_Contract();
        const lotteryId = await contract.methods.currentLotteryId().call();
        setLotteryID(lotteryId);
        const lotteryData = await contract.methods
          .fetchLotteryData(lotteryId)
          .call();
        const pool = await contract.methods.progressivePoolValue().call();
        setPoolvalue((await fromwie(pool)) * 0.93);
        setStartTime(Number(lotteryData.startTime) * 1000);
        setEndTime(Number(lotteryData.endTime) * 1000);
        setLotteryBalance((await fromwie(lotteryData.prizePot)) * 0.93);
        setTotalParticipemts(lotteryData.participants);

        const ticketbuy = await contract.methods
          .ticketsBought(lotteryId, user)
          .call();
        setTicketBought(ticketbuy);

        const price = await contract.methods.ticketPrice().call();
        console.log("Price", await fromwie(price));
        setTicketPrice(await fromwie(price));

        const maxperWallet = await contract.methods
          .maxTicketsPerWallet()
          .call();
        setMaxTicketPerWallet(maxperWallet);

        const token = await contract.methods.token().call();
        setToken(token);
        const tokenContract = await get_ERC_20_Contract(token);
        const usertokenBalance = await tokenContract.methods
          .balanceOf(user)
          .call();
        setTokenBalance(await fromwie(usertokenBalance));

        // const lotterytokenBalance = await tokenContract.methods
        //   .balanceOf(LOTTERY_ADDRESS)
        //   .call();
        // setLotteryBalance(await fromwie(lotterytokenBalance));
        const owner = await contract.methods.owner().call();
        setOwner(convertToLowercase(owner));

        axios
          .get(
            "https://api.coingecko.com/api/v3/simple/price?ids=lucky7&vs_currencies=USD"
          )
          .then((res) => {
            console.log(res.data);
            setTokenPriceAPI(res.data.lucky7.usd);
          })
          .catch((error) => {
            setTokenPriceAPI(0.13);
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, [open, user]);

  useEffect(() => {
    const init = async () => {
      const data = [];
      const contract = await get_Lottery_Contract();
      for (let i = lotteryID; i >= 1; i--) {
        const lotteryData = await contract.methods.fetchLotteryData(i).call();
        lotteryData.ID = i;
        data.push(lotteryData);
      }

      setLotteryHistory(data);
    };
    init();
  }, [lotteryID, user]);

  function generateUniqueAlphaNumeric(length) {
    const characters = "0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  const praticipate = async () => {
    try {
      if (ticketbought == maxTicketPerWallet) {
        toasterror("You already bought all ticket per wallet");
        return;
      }
      const contract = await get_Lottery_Contract();
      const isAllowed = await Allow(user, token, LOTTERY_ADDRESS);
      if (Number(isAllowed) == 0) {
        await Approve(LOTTERY_ADDRESS, token);
      }
      setOpen(true);

      const data = await contract.methods.participate(ticket).send({
        from: user,
        gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
        gasLimit: web3.utils.toHex(500000),
      });
      if (data.status) {
        setOpen(false);
        success("Transaction Success");
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const anouceWinner = async () => {
    try {
      const contract = await get_Lottery_Contract();
      setOpen(true);
      const word1 = generateUniqueAlphaNumeric(9);
      const word2 = generateUniqueAlphaNumeric(9);
      const data = await contract.methods
        .pickWinnersWithContract(word2, word1)
        .send({ from: user });
      if (data.status) {
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const refund = async () => {
    try {
      const contract = await get_Lottery_Contract();
      setOpen(true);
      const data = await contract.methods.claimRefund().send({ from: user });
      if (data.status) {
        setOpen(false);
      }
    } catch (error) {}
  };

  const slice = (str) => {
    const first = str.slice(0, 6);
    const second = str.slice(36);
    return first + "..." + second;
  };

  return (
    <>
      <div className="">
        <div className="bg_home ">
          <div className="">
            <div className="">.</div>
            <div className="h-c-v-c">
              <div className="bg_black t-a-c p-x-3 p-t-0_5 p-b-1 b-r-20">
                <div className="f-w-600 f-s-3   ">
                  ${Number(allpoolvalue * tokenPriceAPI).toFixed(2)}
                </div>
                <div className="f-s-1">Total pot value accumulated</div>
              </div>
            </div>{" "}
            *
            <img src={pot} className="d-b m-a w-26 m-t-9 " />
          </div>
        </div>
        <div className="bg_images">
          <div className="p-y-3">
            <div className="t-a-c m-b-2 ">
              {user && endTime ? (
                <span className="f-s-2 f-w-600 ">
                  {" "}
                  <Countdown date={endTime} />
                </span>
              ) : (
                <span className="f-s-2 f-w-600 ">00:00:00</span>
              )}
              {/* <span className="f-s-1_5 f-w-600 m-r-1">{moment(Number(endTime)*1000).startOf('hour').fromNow()}</span> */}
              {/* <span className="f-s-1_5 f-w-600 m-r-1">d</span>
            <span className="f-s-2 f-w-600 ">2</span>
            <span className="f-s-1_5 f-w-600 m-r-1">h</span>
            <span className="f-s-2 f-w-600 ">30</span>
            <span className="f-s-1_5 f-w-600 m-r-2">m</span> */}
              <span className="f-s-1_5 f-w-600 ">until the next draw</span>
            </div>
          </div>

          <div className="">
            <div className="container-kws p-b-5">
              <div className=" f-s-2 m-b-2">Get your tickets now !</div>
              <div className="b-1  b-r-20">
                <div className="d-f a-i-c j-c-s-b pa-2 flex-wrap">
                  <div className="">Next Draw</div>
                  <div className="">
                    #{lotteryID} Draw :{" "}
                    {moment(
                      endTime ? Number(endTime) : new Date().getTime()
                    ).format("MMMM Do YYYY, h:mm:ss")}
                  </div>
                </div>
                <hr className="pa-0 ma-0" />
                <div className="pa-2">
                  <div className="row m-b-3">
                    <div className="col-6 col-sm-3 f-s-1_25 m-b-3">
                      Prize Pot
                    </div>
                    <div className="col-6 col-sm-6 m-b-3">
                      <div className="f-s-2">
                        {" "}
                        ${Number(lotteryBalance * tokenPriceAPI).toFixed(2)}
                      </div>
                      <div className="f-s-1_5">{lotteryBalance} Lucky7</div>
                    </div>
                    <div className="col-12 col-sm-3 m-b-3">
                      {owenr == user && new Date().getTime() > endTime ? (
                        <div
                          className="bg_blue w-100 f-s-1_25 p-y-12px t-a-c  b-r-20 c-p"
                          onClick={() => anouceWinner()}
                        >
                          Pick winner
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-6 col-sm-3 f-s-1_25">Your Tickets</div>
                    <div className="col-6 col-sm-9">
                      <div className="f-s-1_25">
                        {" "}
                        You have {ticketbought} ticket this round
                      </div>
                    </div>
                  </div>
                  <div className="row a-i-c">
                    <div className="col-12 col-sm-9 m-y-2">
                      <div className="d-f a-i-c j-c-s-b flex-wrap">
                        <div className="m-b-0_5">
                          Ticket Price : {Number(ticketPrice)} (Lucky7 Token){" "}
                        </div>
                        <div className=" m-b-0_5">
                          Lucky7 Balance : {tokenBalance}
                        </div>
                      </div>

                      <div className="d-f bg_dark a-i-c j-c-s-b b-1 b-r-20 p-x-2 p-y-12px">
                        <input
                          type="text"
                          className="w-100  b-c-t b-n f-s-1_25"
                          placeholder="Enter number of ticket"
                          onChange={(e) => {
                            if (Number(e.target.value) > maxTicketPerWallet) {
                              toasterror(
                                `Max per wallet is ${maxTicketPerWallet}`
                              );
                              return;
                            }
                            setTickers(e.target.value);
                          }}
                        />
                        <div className="w-s-n-w">
                          {" "}
                          {ticket * Number(ticketPrice)} Lucky7
                        </div>
                      </div>
                      <div className="m-t-0_5">
                        Min : 1 Ticket &nbsp; &nbsp; Max : {maxTicketPerWallet}{" "}
                        Tickets
                      </div>
                    </div>
                    <div className="col-12 col-sm-3 m-y-2">
                      {!user ? (
                        <div
                          className="bg_blue w-100 f-s-1_25 p-y-12px t-a-c  b-r-20 c-p"
                          onClick={() => handleShow2()}
                        >
                          Connect Wallet
                        </div>
                      ) : new Date().getTime() < endTime ? (
                        <div
                          className="bg_blue w-100 f-s-1_25 p-y-12px t-a-c  b-r-20 c-p"
                          onClick={() => praticipate()}
                        >
                          Buy Ticket
                        </div>
                      ) : typeof totalParticipents == "object" &&
                        totalParticipents.length < 3 ? (
                        <div
                          className="bg_blue w-100 f-s-1_25 p-y-12px t-a-c  b-r-20 c-p"
                          onClick={() => refund()}
                        >
                          Refund
                        </div>
                      ) : (
                        <div className="bg_blue w-100 f-s-1_25 p-y-12px t-a-c  b-r-20 c-n-d o-50">
                          Lottery ended
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-kws">
            <div className="t-a-c f-s-2 f-w-600 m-b-3">History</div>
            <div className="row m-b-5">
              {typeof lotteryHistory == "object" ? (
                lotteryHistory.map((res) => {
                  return (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 m-y-2">
                      <div className="b-1 b-r-10 pa-1">
                        <div className="d-f  a-i-c j-c-s-b m-b-2">
                          <div className="f-s-1_25">Lottery</div>
                          <div className="">#{res.ID}</div>
                        </div>
                        <div className="d-f a-i-c j-c-s-b m-b-1">
                          <div className="f-s-1_25">Expiry Time :</div>
                          <div className="">
                            {new Date(
                              Number(res.endTime) * 1000
                            ).toLocaleString()}
                          </div>
                        </div>
                        <div className="d-f a-i-c j-c-s-b m-b-2">
                          <div className="f-s-1_25">Pot value :</div>
                          <div className="">
                            {((Number(res.prizePot) / 10 ** 9) * 0.93).toFixed(
                              1
                            )}{" "}
                            (Lucky7)
                          </div>
                        </div>
                        <div className="m-b-1 f-s-1_25">Winners </div>
                        <hr />
                        {res.winners.map((res) => {
                          return (
                            <div
                              className="m-t-1 m-q-f-s-0_8 w-b-b-a"
                              onClick={() => {
                                navigator.clipboard.writeText(res).then(() => {
                                  success("Copied");
                                });
                              }}
                            >
                              {res}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>Loading...</div>
              )}
              {typeof lotteryHistory == "object" &&
              lotteryHistory.length == 0 ? (
                <div>No data found</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="container-kws">
            <div className="t-t-u f-w-600 d-f a-i-c m-b-3">
              <AiOutlineCopyrightCircle className="f-s-1_5 " /> &nbsp; 2023
              Designed by &nbsp;
              <a
                href="https://kesaviwebsolutions.com/"
                target="_blank"
                className="c-p t-d-u"
              >
                {" "}
                kesavi web solutions
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show2} onHide={handleClose2} centered>
        <div className="">
          <Modal.Header>
            <Modal.Title className="w-100">
              <div className="d-f a-i-c j-c-s-b">
                <div className="">Please Connect Your Wallet</div>
                <div className="">
                  <RxCross2 className="f-s-2" onClick={() => handleClose2()} />
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
              <img
                src={meta}
                alt=""
                className="w-7"
                onClick={() => {
                  Metamask();
                  handleClose2();
                }}
              />
              <img
                src={walletconnect}
                alt=""
                className="w-5"
                onClick={() => {
                  WalletC();
                  handleClose2();
                }}
              />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Home;

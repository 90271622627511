
import Navbar from "./component/pages/Navbar";
import "./component/scss/Main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/pages/Home";
import "./App.scss";
import Form1 from "./component/pages/Form1";
import Page404 from "./component/pages/Page404";
import { useEffect } from "react";
import { useStoreActions } from "easy-peasy";
import { DissconnectWallet, GetChainId, MetaMasklogin, WalletConnect, convertToLowercase, getUserAddress, mainprovider } from "./Web3/web3";

function App() {
  const web3 = mainprovider()
  const setUser = useStoreActions((action)=> action.setUser)

  useEffect(() => {
    const init = async () => {

      const wallet = window.localStorage.getItem("wallet");
      if (wallet) {
        await Metamask();
      }
      const id = await GetChainId();
      if (Number(id) != 56) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x38" }], // chainId must be in hexadecimal numbers 38
        });
      }
    };

    init();
  }, []);

  const WalletC = async () => {
    console.log("walletConnect")
    await WalletConnect();
    const add = await getUserAddress();
    setUser(convertToLowercase(add));

  };

  const Metamask = async () => {
    console.log("Metamask")
    await MetaMasklogin();
    const add = await getUserAddress();
    window.localStorage.setItem("wallet", "wallet");
    setUser(convertToLowercase(add));
  };



  const Dissconnect = async () => {
    console.log("Dissconnect")
    await DissconnectWallet();
    setUser(undefined);
    window.localStorage.removeItem("wallet");
  };

  try {
    web3.on("accountsChanged", function (accounts) {
      // setUser(accounts[0]);
      setUser(convertToLowercase(accounts[0]));
    });
  } catch (error) { 
    console.log(web3)
  }

  try {
    web3.on("chainChanged", function (accounts) {
      window.location.reload();
    });
  } catch (error) { }

  return (
    <div className="">
      <Router>
        <Navbar Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>
        <Routes>
          <Route path="/" element={<Home Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />
          <Route path="/form" element={<Form1 />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect, useLayoutEffect, useState } from "react";

import { ImCross } from "react-icons/im";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import image_dummy from "../Image/pot.png";
import { RxCross2 } from "react-icons/rx";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineMenu } from "react-icons/ai";

import Modal from "react-bootstrap/Modal";
import meta from "../Image/meta.png";
import walletconnect from "../Svg/walletconnect.svg";
import "animate.css";

import logo from "../Image/nfe-logo.png";
import { useStoreState } from "easy-peasy";
import { fromwie, get_ERC_20_Contract, get_Lottery_Contract } from "../../Web3/web3";

function Navbar({ Dissconnect, Metamask, WalletC }) {
  const [show, setShow] = useState(false);
  const user = useStoreState((state) => state.user);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState();
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [maxTicketPerWallet, setMaxTicketPerWallet] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(0);
  const [tokenname, setTokenName] = useState('')
  const [symbol, setSymbol] = useState('')

  const sliceaddress = (add) => {
    if (typeof add === "string") {
      const first = add.slice(0, 5);
      const seconds = add.slice(36);
      return first + "..." + seconds;
    }
  };
  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "light-theme") {
      setTheme("light-theme");
    } else {
      setTheme("dark-theme");
    }
  }, []);

  const [theme, setTheme] = useState("dark-theme");
  const toggleTheme = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "dark-theme"
    );
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    const init = async () => {
      try {
        const contract = await get_Lottery_Contract();
   
        const price = await contract.methods.ticketPrice().call();
        setTicketPrice(await fromwie(price));

        const maxperWallet = await contract.methods.maxTicketsPerWallet().call();
        setMaxTicketPerWallet(maxperWallet);

        const token = await contract.methods.token().call();
  
        const tokenContract = await get_ERC_20_Contract(token);
        const name = await tokenContract.methods.name().call();
        setTokenName(name)

        const sym = await tokenContract.methods.symbol().call();
        setSymbol(sym)

      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, [open, user]);

  const [show_guide, setShow_guide] = useState(false);

  return (
    <>
      <div className="ps-a t-0 w-100">
        <div className="p-x-3 p-y-1_5">
          <div className=" navbar12 f-s-1_25  d-f a-i-c j-c-s-b p-y-1">
            <div className="">
              <ul className="l-s-t-n d-f a-i-c ">
                <li className="m-r-1">
                  <img
                    src={logo}
                    alt=""
                    className="logo animate__animated animate__rubberBand w-3"
                  />
                </li>

                <li className="m-r-2 c-p   m-q-b-d-n">Project 7</li>
              </ul>
            </div>
            <div className=" c-p ">
              <ul className="l-s-t-n d-f a-i-c ">
                <li className="" onClick={() => setShow_guide(true)}>
                  Guide
                </li>
                {/*     <li className="m-l-2 c-pq ">
            <span className="" onClick={() => toggleTheme()}>{theme === "dark-theme" ? "Light mode" : "Dark mode"}</span>
            </li> */}
                <li className="m-l-2 c-pq ">
                  {" "}
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (user) {
                        Dissconnect();
                        return;
                      }
                      setShow2(true);
                    }}
                    className="bg_blue c-w b-n f-s-1_25 p-x-3 p-y-0_5 b-r-20"
                  >
                    {user ? sliceaddress(user) : "CONNECT WALLET"}{" "}
                  </Button>
                </li>
                {/*    <li className="m-l-2 c-p   m-q-a-d-n ">
                {" "}
                <Button onClick={handleShow} className=" b-c-t c-i b-n pa-0">
                  <AiOutlineMenu />
                </Button>
              </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------mobile-navbar---------------------------------- */}

      <Offcanvas show={show} onHide={handleClose} className="pa-1">
        <Offcanvas.Header>
          <Offcanvas.Title>
            {" "}
            <div className="image-dog1">
              <a href="/" target="_blank">
                <img src={logo} alt="" className="" />
              </a>
            </div>
          </Offcanvas.Title>
          <ImCross onClick={handleClose} color="black" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="l-s-t-n">
            <li className="m-r-1 m-t-1 c-p">dummy</li>
            <li className="m-r-1 m-t-1 c-p">dummy</li>
            <li className="m-r-1 m-t-1 c-p">dummy</li>
            <li className="m-r-1 m-t-1 c-p">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="b-c-t c-i b-n"
                >
                  Dropdown Button
                </Dropdown.Toggle>
                <Dropdown.Menu className="pa-1">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={show2} onHide={handleClose2} centered>
        <div className="">
          <Modal.Header>
            <Modal.Title className="w-100">
              <div className="d-f a-i-c j-c-s-b">
                <div className=""> Please Connect Your Wallet</div>
                <div className="">
                  <RxCross2 className="f-s-2" onClick={() => handleClose2()} />
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
              <img
                src={meta}
                alt=""
                className="w-7"
                onClick={() => {
                  Metamask();
                  handleClose2();
                }}
              />
              <img
                src={walletconnect}
                alt=""
                className="w-5"
                onClick={() => {
                  WalletC();
                  handleClose2();
                }}
              />
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={show_guide}
        onHide={() => setShow_guide(false)}
        size="lg"
        id="modal_guide"
      >
        <div className="pa-2 max-h-90vh o-a">
          <div className="d-f a-i-c j-c-s-b m-b-2">
            <div className="f-s-2 f-w-600  ">Participation Guide</div>
            <div className="">
              <RxCross2
                className="f-s-2 c-p"
                onClick={() => setShow_guide(false)}
              />
            </div>
          </div>

        
          <div className="m-y-2">
            <p>Each wallet can buy a maximum of {maxTicketPerWallet} tickets per draw. Each ticket costs 10 Lucky7 tokens and 
              represents 1 entry in the draw. 5 tickets = 5 entries. The more entries a wallet has, the higher the chances of 
              winning.
              <br/>
              Each draw is scheduled for 1 week. And as soon as winners are drawn, the next lottery will begin 
              immediately.
            </p>
            <br/>
            <br/>
            <p>Steps to participate</p>
            <ol>
              <li>Buy Lucky7 tokens from <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0xb89f7f1278e799ceb78d909ed8391de5a223ae12" target="_blank" className="anchor">Dextools</a></li>
              <li>Connect your wallet that has Lucky7 token balance</li>
              <li>Input number of tickets you want to buy (max {maxTicketPerWallet} per wallet allowed)</li>
              <li>Buy tickets by paying the price equivalent to price * number of tickets</li>
              <li>Wait for the draw</li>
            </ol>

            <p>Wishing you good luck!</p>
            
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Navbar;
